.search-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 270px; */
  width: 100%;
  padding: 30px 5%;
}

.search-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  transform: scaleX(-1);
  z-index: -1;
}

.search-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  min-width: 890px;
}

.search-title {
  margin-bottom: 20px;
}

.search-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.search-title p {
  margin-top: 25px;
  margin-bottom: 10px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

.search-box-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 10px;
  /* margin-bottom: 30px; */
  position: relative;
  align-items: center;
  gap: 10px;
}

.search-box {
  height: 50px;
  width: 100%;
  max-width: 800px;
  /* margin-right: 20px; */
  padding: 10px 40px 10px 15px;
  position: relative;
  /* border-radius: 0; */
}

.search-box-container .clear-icon {
  position: absolute;
  left: 770px;
  top: 50%;
  /* right: 10px; */
  /* left: 520px; */
  transform: translateY(-50%);
  cursor: pointer;
  z-index : 1;
}

.search-button {
  width: 140px;
  min-width: 120px;
  border-radius: 32px;
  background-color: var(--myasu-maroon);
}

.search-button svg {
  margin-right: 5px;
}

.search-button:hover {
  transform: scale(1.05);
  background-color: var(--myasu-maroon);
}

.tabs-container {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-top: 4px;
  border-bottom: 1px solid #797979;
}

.tab {
  padding: 5px 20px;
  cursor: pointer;
}

.active-tab {
  border-bottom: 6px solid var(--myasu-maroon);
}

.disabled-tab {
  pointer-events: none;
  opacity: 0.5;
}


.keyword-suggestions {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  /* flex-direction: column; */
  /* background-color: #e8e8e8; */
  background-color: #e8e8e8;
  border-radius: 10px;
  padding: 10px 10px;
  margin-top: 5px;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 5px;
}

.keyword-suggestions .keyword{
  font-size: 12px;
  margin-right: 5px;
  /* background-color: var(--myasu-gold); */
  /* background-color: #e8e8e8; */
  background-color: #fff;
  color: #0366d6;
  padding: 5px 10px;
  border-radius: 20px;
  /* margin-bottom: 8px; */
  cursor: pointer;
}

.keyword-suggestions .suggestions-title {
  font-size: 14px;
  font-weight: bold;
  margin-right: 8px;
}

.keyword-suggestions .keyword-suggestions-container {
  display: flex;
  flex-direction: row;
}

.custom-header {
  display: flex;
  align-items: center;
}

.count-badge {
  background-color: var(--myasu-maroon);
  border: 1px solid #000;
  color: #fff;
  display: inline-block;
  padding: 0.25em 0.4em;
  margin: 2px 5px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.count-badge-inverse {
  background-color: #f6f6f6;
  border: 1px solid #000;
  color: var(--myasu-maroon);
  display: inline-block;
  padding: 0.25em 0.4em;
  margin: 2px 0 2px 5px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-container  .tab-content {
  display: none;
}

.search-container  .tab-content.active {
  display: block;
}

