.air-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.air-container .main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 270px; */
  width: 100%;
  padding: 30px 5%;
}

.air-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  transform: scaleX(-1);
  z-index: -1;
}

.air-container .search-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  min-width: 890px;
}

.air-container .search-title {
  margin-bottom: 20px;
}

.air-container .search-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.air-container .search-title p {
  margin-top: 25px;
  margin-bottom: 10px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

.air-container .search-box-container {
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  /* margin-bottom: 30px; */
  position: relative;
}

.air-container .search-box {
  height: 100px;
  width: 800px;
  margin-right: 20px;
  padding: 10px 25px 10px 15px;
  resize: none;
  /* border-radius: 0; */
}

.air-container .clear-icon {
  position: absolute;
  top: 50%;
  /* right: 290px; */
  left: 760px;
  transform: translateY(-50%);
  cursor: pointer;
}

.air-container .search-button {
  width: 140px;
  min-width: 120px;
  border-radius: 32px;
  background-color: var(--myasu-maroon);
}

.air-container .search-button svg {
  margin-right: 5px;
}

.air-container .search-button:hover {
  transform: scale(1.05);
  background-color: var(--myasu-maroon);
}

.air-container .table-container {
  display: flex;
  position: relative;
}


.air-search-data {
  background-color: #f6f6f6;
  max-height: 480px;
  width:54%;
}

.air-search-data.full-width {
  width: 100%;
}

/* .air-search-data .no-data-text {
  width: 100%;
} */

.air-container .tabs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 20px; */
  position: relative;
}

.air-container .tabs-left {
  display: flex;
}

.air-container .export-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.air-container .export-container .export-link {
  text-decoration: none;
  color: var(--myasu-maroon);
  font-weight: bold;
  display: flex;
  align-items: center;
}

.air-container .export-container .export-icon {
  margin-right: 5px;
}

.air-container .export-container.hidden {
  display: none;
}

.aiir-accordian-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.air-container .score-text {
  background-color: var(--myasu-maroon);
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 11px;
  font-weight: bold;
  margin-top: 5px;
}


.matching-chunk {
  margin: 15px 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.matching-chunk p {
  margin: 0;
  line-height: 1.6;
  color: #333;
}

.chunk-divider {
  margin: 15px 0;
  border: none;
  border-top: 1px solid #e9ecef;
}

.matching-chunk:last-child .chunk-divider {
  display: none;
}

.accordion-content {
  /* margin: 10px; */
  padding: 15px;
  border-radius: 0 0 4px 4px;

}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #f8f9fa;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 2px;
}

.accordion-icon {
  transition: transform 0.3s ease;
  font-size: 18px;
}

.accordion-icon.open {
  transform: rotate(45deg);
}